






































































































import Component from 'vue-class-component'
import { Action, Getter, Mutation } from 'vuex-class'
import MediaUploader from '@/components/common/MediaUploader.vue'
import CustomSelect from '@/components/common/CustomSelect.vue'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import Unescape from '@/common/unescape'
import PhotoMutation from '@/common/getPhotoMutation'
import {
  GET_PROFILE_DATA_EDIT,
  SAVE_OWN_PROFILE
} from '@/store/company/actions'
import { CompanyProfile } from '@/store/company/types'
import { FileRequest, FileDto, SimpleServerResponse, User, GenericRequest } from '@/store/types'
import { Form, KeyValue, MediaFile } from '@/store/commonFormInput/types'
import { MU_SET_PROFILE_DATA } from '@/store/company/mutations'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import BaseForm from '@/components/forms/BaseForm.vue'
import InputTypes from '@/common/formTypes'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import Rights, { COMPANY_OWN_PROFILE_READ } from '@/common/rights'
import { DELETE_PHOTO, GET_PHOTO, SAVE_PHOTO, SET_ACTIVATION_STATUS_OWN_PROFILE } from '@/store/user/actions.type'
import { MU_USER_SET_PHOTO_FILENAME } from '@/store/user/mutation.type'
import userDomain from '@/store/common/userDomain'
import PreviewHandler from '@/components/common/PreviewHandler.vue'
import MetaComponent from '@/common/MetaComponent.vue'
@Component({
  components: { PreviewHandler, BaseForm, ConfirmationModal, CustomSelect, MediaUploader, SubmitSuccess }
})
export default class ProfileEditor extends MetaComponent {
  @Action(GET_PROFILE_DATA_EDIT)
  public getProfile: () => Promise<CompanyProfile>

  @Action(GET_PHOTO)
  public getLogo: (role: string) => Promise<FileDto>

  @Action(SAVE_OWN_PROFILE)
  public saveOwnProfile: (data: CompanyProfile) => Promise<SimpleServerResponse>

  @Action(SAVE_PHOTO)
  public saveLogo: (data: FileRequest) => Promise<SimpleServerResponse>

  @Action(SET_ACTIVATION_STATUS_OWN_PROFILE)
  public setActivationStatusForOwnProfile: (data: GenericRequest) => Promise<SimpleServerResponse>

  @Action(DELETE_PHOTO)
  public deleteLogo: (role: string) => Promise<SimpleServerResponse>

  @Getter('userGetter') userGetter: User

  @Mutation(MU_SET_PROFILE_DATA)
  public setProfileData: (profile: CompanyProfile) => void

  @Mutation(MU_USER_SET_PHOTO_FILENAME)
  public setLogoFilename: (filename: string) => void

  @Getter('photoFileNameGetter') persistedLogo: string | undefined
  @Getter('companyProfileGetter') persistedProfile: CompanyProfile | undefined

  logo: MediaFile = {}
  type: string | undefined = ''
  profileId: string | undefined = undefined
  logoSaved = false
  isPublic = 'not_accepted'
  submitSuccess = false
  feedbackHeadline = ''
  feedbackInfo = ''

  get industries (): KeyValue[] {
    return Object.keys(this.$i18n.t('INDUSTRIES')).map(key => {
      return {
        value: key,
        text: this.$i18n.t('INDUSTRIES.' + key).toString()
      } as KeyValue
    })
  }

  get types (): KeyValue[] {
    return Enums.TYPES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('company.profile.TYPES.' + key).toString()
      } as KeyValue
    })
  }

  get sizes (): KeyValue[] {
    return Enums.COMPANY_SIZES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('COMPANY_SIZES.' + key).toString()
      } as KeyValue
    })
  }

  get salutations (): KeyValue[] {
    return Enums.SALUTATIONS.map(key => {
      return {
        value: key,
        text: this.$i18n.t('personal-information.SALUTATIONS.' + key).toString()
      } as KeyValue
    })
  }

  get titles (): KeyValue[] {
    return Enums.TITLES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('personal-information.TITLES.' + key).toString()
      } as KeyValue
    })
  }

  get hasPreviewRight (): boolean {
    return hasRight(COMPANY_OWN_PROFILE_READ)
  }

  savedProfileSuccessfully = false

  profile: CompanyProfile = {
    companyName: '',
    industry: '',
    type: '',
    employees: '',
    website: 'https://',
    logo: {},
    description: '',
    supplement: '',
    street: '',
    number: '',
    postalCode: '',
    city: '',
    region: '',
    country: '',
    salutation: '',
    title: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: ''
  }

  get companyFields (): Form[] {
    return [
      {
        id: 'companyName',
        key: 'companyName',
        type: InputTypes.TEXT,
        label: 'company.profile.name'
      },
      {
        id: 'type',
        key: 'type',
        type: InputTypes.SELECT,
        label: this.$i18n.t('company.profile.type').toString(),
        options: this.types
      },
      {
        id: 'industry',
        key: 'industry',
        type: InputTypes.SELECT,
        label: this.$i18n.t('company.profile.industry').toString(),
        options: this.industries
      },
      {
        id: 'employees',
        key: 'employees',
        type: InputTypes.SELECT,
        label: this.$i18n.t('company.profile.size').toString(),
        options: this.sizes
      },
      {
        id: 'website',
        key: 'website',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.website').toString()
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      },
      {
        id: 'logo',
        key: 'logo',
        type: InputTypes.PHOTO,
        label: this.$i18n.t('company.profile.logo').toString()
      },
      {
        id: 'description',
        key: 'description',
        type: InputTypes.TEXTAREA,
        label: this.$i18n.t('company.profile.description').toString()
      }
    ]
  }

  get addressFields (): Form[] {
    return [
      {
        id: 'supplement',
        key: 'supplement',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.addressAppendix').toString()
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      },
      {
        id: 'street',
        key: 'street',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.street').toString()
      },
      {
        id: 'number',
        key: 'number',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.number').toString()
      },
      {
        id: 'postalCode',
        key: 'postalCode',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.zipCode').toString()
      },
      {
        id: 'city',
        key: 'city',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.city').toString()
      },
      {
        id: 'country',
        key: 'country',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.country').toString()
      }
    ]
  }

  get partnerFields (): Form[] {
    return [
      {
        id: 'salutation',
        key: 'salutation',
        type: InputTypes.SELECT,
        label: this.$i18n.t('personal-information.salutation').toString(),
        options: this.salutations
      },
      {
        id: 'title',
        key: 'title',
        type: InputTypes.SELECT,
        label: this.$i18n.t('personal-information.title').toString(),
        options: this.titles
      },
      {
        id: 'firstname',
        key: 'firstname',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.firstName').toString()
      },
      {
        id: 'lastname',
        key: 'lastname',
        type: InputTypes.TEXT,
        label: this.$i18n.t('personal-information.lastName').toString()
      },
      {
        id: 'contactEmail',
        key: 'contactEmail',
        type: InputTypes.EMAIL,
        label: this.$i18n.t('personal-information.email').toString()
      },
      {
        id: 'phone',
        key: 'phone',
        type: InputTypes.PHONE,
        label: this.$i18n.t('personal-information.phone').toString()
      }
    ]
  }

  get contactFields (): Form[] {
    return [
      {
        id: 'email',
        key: 'email',
        type: InputTypes.EMAIL,
        label: this.$i18n.t('personal-information.email').toString(),
        disabled: true
      }
    ]
  }

  get writeable (): boolean {
    return hasRight(Rights.STUDENT_OWN_PROFILE_READ)
  }

  get readonly (): boolean {
    return hasRight(Rights.STUDENT_OWN_PROFILE_READ)
  }

  callDeleteLogo (logoMutation: string): Promise<SimpleServerResponse> {
    if (logoMutation === 'CHANGED' || logoMutation === 'DELETED') {
      return this.deleteLogo(userDomain.COMPANY)
    } else {
      return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
    }
  }

  callSaveLogo (): Promise<SimpleServerResponse> {
    const logoMutation = PhotoMutation.getPhotoMutation(this.persistedLogo, this.profile.logo)

    return this.callDeleteLogo(logoMutation).then(() => {
      if (logoMutation === 'CHANGED' || logoMutation === 'ADDED') {
        if (this.profile.logo && this.profile.logo.base64ImageData !== undefined && this.profile.logo.fileForUpload !== undefined) {
          const file: FileDto = {
            base64Content: this.profile.logo.base64ImageData,
            filename: this.profile.logo.fileForUpload.name
          }
          const dto: FileRequest = {
            role: userDomain.COMPANY,
            file: file
          }

          return this.saveLogo(dto)
        } else {
          return Promise.reject(Error('image not available'))
        }
      } else {
        return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
      }
    }).catch((error) => {
      return Promise.reject(error) as Promise<SimpleServerResponse>
    }) as Promise<SimpleServerResponse>
  }

  clearLogo (): void {
    this.$set(this.profile, 'logo', { })
  }

  activationButtonClick (): void {
    const requestData: GenericRequest = {
      query: ['', 'COMPANY'],
      params: undefined
    }

    switch (this.profile.activationStatus) {
      case 'ACTIVE':
        requestData.query[0] = 'false'
        break
      case 'DEACTIVATED':
        requestData.query[0] = 'true'
        break
      case 'ACTIVATION_REQUESTED':
        requestData.query[0] = 'false'
        break
      default:
        requestData.query[0] = 'true'
    }

    this.$root.$emit('load')
    this.setActivationStatusForOwnProfile(requestData).then((data) => {
      this.profile.activationStatus = data.content
    }).catch(() => {
      this.$root.$emit('alert', this.$t('warning.error!').toString(), this.$t('warning.activation-failed').toString(), true)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  save (): Promise<void> {
    return this.saveOwnProfile(this.profile)
      .then(() => {
        this.savedProfileSuccessfully = true
      })
      .catch(() => {
        this.savedProfileSuccessfully = false
      }).finally(() => {
        return this.callSaveLogo()
          .then(() => {
            this.logoSaved = true
          })
          .catch(() => {
            this.logoSaved = false
          })
      })
  }

  saveAndShowInfo (event: Event): void {
    this.$root.$emit('load')
    this.save().then(() => {
      this.showInfo()
    }).catch(() => {
      this.showInfo()
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  private showInfo (): void {
    this.submitSuccess = this.savedProfileSuccessfully && this.logoSaved

    this.$root.$emit(
      'alert',
      this.$i18n.t(this.submitSuccess ? 'info.save-success' : 'info.save-failed', {
        what: this.$i18n.t(this.submitSuccess ? 'main-menu-company.profile' : this.savedProfileSuccessfully ? 'company.profile.logo' : 'main-menu-company.profile')
      }).toString(),
      '',
      !this.submitSuccess
    )
  }

  fetchProfileAndUpdateModels (onlyUpdateId: boolean, updateComparisonObject: boolean): Promise<string> {
    this.$root.$emit('load')
    return this.getProfile().then((data: CompanyProfile) => {
      this.profileId = data.profileId
      this.profile = data
      this.$set(this.profile, 'website', data.website ? data.website : 'https://')
      if (!onlyUpdateId) {
        this.isPublic = data.activationStatus === 'DEACTIVATED' || data.activationStatus === null ? 'not_accepted' : 'accepted'
        this.type = data.type
      }
      if (updateComparisonObject) {
        this.setProfileData(data)
      }
      return Promise.resolve('done') as Promise<string>
    }).catch(error => {
      if (error.message === 'No Profile found for account') {
        return Promise.resolve('done') as Promise<string>
      } else {
        this.$root.$emit('alert', this.$i18n.t('warning.error!').toString(), error.message)
        return Promise.reject(Error(error.message)) as Promise<string>
      }
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  public showPreview (event: Event): void {
    this.saveAndShowInfo(event)
    this.$nextTick(() => {
      const lang = this.$i18n.locale.toString()
      const routeData = this.$router.resolve({ name: 'company-profile-preview' })
      const target = window.location.origin + '/' + lang + routeData.href
      window.open(target, '_blank')
    })
  }

  created (): void {
    this.fetchProfileAndUpdateModels(false, true).then(() => {
      this.getLogo(userDomain.COMPANY).then(data => {
        this.setLogoFilename(data.filename)
        this.$set(this.profile, 'logo', { file: data.base64Content })
      }).catch(() => {
        this.$set(this.profile, 'logo', { })
      })
    })

    /*
    const root = document.getElementById('profile-editor')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }
     */
  }
}
