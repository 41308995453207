

































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'

@Component({
  components: { ConfirmationModal }
})
/*
This component can be used whenever something could have a preview (like a job ad or a profile).
It adds a link to open the preview. By default a modal dialog asks the user to save and then opens the preview in a new tab.
 */
export default class PreviewHandler extends Vue {
  // to disable the link
  @Prop({ default: false })
  disabled: boolean

  // if a modal dialog to save should be shown
  @Prop({ default: true })
  saveConfirmation: boolean

  saveContent (e: Event): void {
    this.$emit('confirm', e)
  }

  clickAction (): void {
    if (this.saveConfirmation) this.$bvModal.show('confirmation-modal-save-before')
    else this.$emit('confirm')
  }
}
